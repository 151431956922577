import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{staticClass:"pb-0"},[_c(VCol,{attrs:{"cols":"12","md":"8"}},[_c(VCard,{staticClass:"pb-0",attrs:{"flat":""}},[_c(VCardTitle,[_vm._v(" Motivacional mais importantes ")]),_c(VCardSubtitle,[_vm._v(" Média ponderada")]),_c(VCardText,[_c('vue-apex-charts',{attrs:{"type":"bar","height":"350","options":_vm.graficosBarOpcoes.chartOptions,"series":_vm.graficosBarOpcoes.series}})],1)],1)],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VCard,{staticClass:"pb-0",attrs:{"flat":""}},[_c(VCardTitle,[_vm._v(" Motivacional mais importantes ")]),_c(VCardSubtitle,[_vm._v(" Percentual")]),_c(VCardText,[_c('vue-apex-charts',{attrs:{"type":"donut","height":"350","options":_vm.graficosDonutOpcoes.chartOptions,"series":_vm.graficosDonutOpcoes.series}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }