<template>
  <div>
    <v-row class="pb-0">
      <v-col cols="12" md="8">
        <v-card flat class="pb-0">
          <v-card-title> Motivacional mais importantes </v-card-title>
          <v-card-subtitle> Média ponderada</v-card-subtitle>
          <v-card-text>
            <vue-apex-charts
              type="bar"
              height="350"
              :options="graficosBarOpcoes.chartOptions"
              :series="graficosBarOpcoes.series"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card flat class="pb-0">
          <v-card-title> Motivacional mais importantes </v-card-title>
          <v-card-subtitle> Percentual</v-card-subtitle>
          <v-card-text>
            <vue-apex-charts
              type="donut"
              height="350"
              :options="graficosDonutOpcoes.chartOptions"
              :series="graficosDonutOpcoes.series"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import store from "@/store";

import { computed, onUpdated } from "@vue/composition-api";
import themeConfig from "@themeConfig";

export default {
  components: {
    VueApexCharts,
  },
  setup() {
    const $chartColors = themeConfig.colors;
    let total = 0;
    let percent = [];

    const motivacionalData = computed(
      () => store.getters["pesquisas/getMotivacionalPainel"]
    );

    const graficosBarOpcoes = {
      series: [
        {
          name: "Total",
          data: motivacionalData.value.resultado,
        },
      ],
      chartOptions: {
        chart: {
          toolbar: {
            show: true,
          },
        },
        colors: $chartColors.green.color,
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "left",
          fontSize: "14px",
          fontFamily: '"Inter", sans-serif',
        },
        stroke: {
          show: true,
          colors: ["transparent"],
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: true,
          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#fff",
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: "#000",
              opacity: 0.45,
            },
          },
        },
        xaxis: {
          categories: motivacionalData.value.labels,
        },
        yaxis: {
          // opposite: isRtl,
        },
      },
    };

    motivacionalData.value.resultado.map(function (e) {
      total += e;
    });
    motivacionalData.value.resultado.map(function (e, i) {
      percent[i] = parseFloat(((e / total) * 100).toFixed(2));
    });

    const graficosDonutOpcoes = {
      series: percent,
      chartOptions: {
        chart: {
          toolbar: {
            show: true,
          },
        },
        legend: {
          show: false,
        },
        colors: $chartColors.yellow.variants.gradient1,
        dataLabels: {
          enabled: true,
          formatter(val) {
            // eslint-disable-next-line radix
            return `${parseInt(val)}%`;
          },
        },
        labels: motivacionalData.value.labels,
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: "20px",
                  fontFamily: '"Inter", sans-serif',
                },
                value: {
                  fontSize: "20px",
                  fontFamily: '"Inter", sans-serif',
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}%`;
                  },
                },
                total: {
                  show: true,
                  fontSize: "1rem",
                  label: "Total",
                  formatter() {
                    return `100%`;
                  },
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 380,
              },
              legend: {
                position: "bottom",
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: 320,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: "0.5rem",
                      },
                      value: {
                        fontSize: "0.5rem",
                      },
                      total: {
                        fontSize: "0.5rem",
                      },
                    },
                  },
                },
              },
              legend: {
                show: true,
              },
            },
          },
        ],
      },
    };
    return { graficosBarOpcoes, graficosDonutOpcoes };
  },
};
</script>

<style></style>
